@font-face {
  font-family: RifficFree-Bold;
  src: url("RifficFree-Bold.c5dd0546.ttf");
}

body {
  background: #000;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

/*# sourceMappingURL=index.65b8086a.css.map */
