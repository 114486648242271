@font-face {
    font-family: 'RifficFree-Bold';
    src: url('../static/assets/fonts/RifficFree-Bold.ttf');
}

body {
    background:black;
    margin:0;
    padding:0;
    overflow:hidden;
}
